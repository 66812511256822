import { Button, Typography } from "@material-tailwind/react";
import React from "react";
import Logo from "../logo.svg";
import facebook from "../assets/facebook-white.png";
import youtube from "../assets/youtube-white.png";
import instagram from "../assets/instagram-white.png";
import shopee from "../assets/shopee-white.png";
import tokopedia from "../assets/tokopedia.webp";
// import tiktok from "../assets/tiktok-white.png";
import tiktok from "../assets/tiktokshop-white.png";
import { Fainstagram, FaInstagram, FaWhatsapp } from "react-icons/fa";

const Footer = ({ refs }) => {
  const navPageWithRef = (page) => {
    const result = refs.find(({ pageRef }) => pageRef === page);
    console.log(result);
    result.ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const navPage = (page) => {
    const id = page;
    const yOffset = -75;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };
  return (
    // <div className="flex flex-col h-auto gap-4 py-2 px-6 bg-[#393939]">
    //     {/* Row Pertama */}
    //     <div className="flex justify-center flex-col">
    //         <div className="flex justify-center">
    //             <div className="flex flex-col items-center text-white ">
    //                 {/* <Typography className="mr-4 py-1.5 font-medium">
    //                 <img src={Logo} className="w-50 h-7" alt="logo" />
    //                 </Typography> */}
    //                 <h1>Priapus Essentials</h1>
    //                 <h1>Authorized seller of Wellous</h1>
    //             </div>
    //         </div>
    //         <div className="flex justify-center">
    //             <div className="flex flex-col">
    //                 {/* <h1 className="text-lg font-bold mb-1">Contact Us</h1> */}
    //                 <div className="flex flex-row">
    //                     <Typography className="mr-4 py-1.5 font-medium">
    //                     {/* <FaInstagram size="2.7rem" color="#b8b8b8"/> */}
    //                     <img src={instagram} className="w-50 h-7" alt="logo" />
    //                     </Typography>
    //                     <Typography className="mr-4 py-1.5 font-medium">
    //                     {/* <FaFacebook size="2.7rem" color="#b8b8b8"/> */}
    //                     <img src={facebook} className="w-50 h-7" alt="logo" />
    //                     </Typography>

    //                     <Typography className="mr-4 py-1.5 font-medium">
    //                     <img src={shopee} className="w-50 h-7" alt="logo" />
    //                     </Typography>
    //                     <Typography className="mr-4 py-1.5 font-medium">
    //                     <img src={tokopedia} className="w-50 h-7" alt="logo" />
    //                     </Typography>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    //     {/* row kedua */}
    //     <div className="flex justify-center border-t-2 border-t-gray-500">
    //         <div className="flex justify-center items-center md:text-base text-xs text-white ">
    //             PRIAPUS ESSENTIALS Copyright © 2024 All rights reserved
    //         </div>
    //     </div>
    // </div>
    <div className="h-auto md:px-14 px-2 py-6 overflow-hidden flex flex-col gap-4 bg-[#393939]">
      {/* Row pertama */}
      <hr />
      <div className="grid md:grid-cols-4 grid-cols-1">
        <div className="block w-3/5">
          <h1 className="text-xl font-bold mb-7 text-white">Priapus Store</h1>
          <div className="flex">
            <Typography className="mr-4 pt-4 font-medium">
              {/* <FaInstagram size="2.7rem" color="#b8b8b8"/> */}
              <img src={tokopedia} className="w-full h-7" alt="logo" />
            </Typography>
            <Typography className="mr-1.5 py-1.5 font-medium">
              {/* <FaInstagram size="2.7rem" color="#b8b8b8"/> */}
              <img src={shopee} className="w-full h-7" alt="logo" />
            </Typography>
            <Typography className="mr-4 py-1.5 font-medium">
              {/* <FaInstagram size="2.7rem" color="#b8b8b8"/> */}
              <img src={tiktok} className="w-full h-8" alt="logo" />
            </Typography>
          </div>
          <h1 className="text-white my-2">
            Priapus Essentials Copyright © 2024 All rights reserved
          </h1>
        </div>
        <div className="hidden lg:block">
          <h1 className="text-lg font-bold mb-7 text-white">
            Hours of Operation
          </h1>
          <h1 className="text-white">Mon-Thu: 9AM to 8PM</h1>
          <h1 className="text-white">Friday: 9AM to 3PM</h1>
          <h1 className="text-white">Sat-Sun: Closed</h1>
        </div>
        <div className="">
          <h1 className="lg:text-lg font-bold mb-7 text-white">Contact Us</h1>
          <h1 className="text-white">Instagram: <span className="cursor-pointer" onClick={() => window.open('https://www.instagram.com/priapus.essentials/')}>priapus.essenstials</span></h1>
          <h1 className="text-white">Facebook: <span className="cursor-pointer" onClick={() => window.open('https://www.facebook.com/priapusessentials')}>priapusessenstials</span></h1>
          <h1 className="text-white">Whatsapp: <span className="cursor-pointer" onClick={() => window.open('https://gass.priapus.store/cta/')}>+6281295212867</span></h1>
          <h1 className="text-white">Tiktok: <span className="cursor-pointer" onClick={() => window.open('https://www.tiktok.com/@priapus.essential')}>@priapus.essenstials</span></h1>
        </div>
        <div className="md:flex gap-4 flex-col hidden text-white">
          {/* Pabrik */}
          <div>
            <h1 className="lg:text-lg font-bold mb-7">Menu</h1>
            <ul class="list-disc list-inside my-2">
              <li onClick={() => navPage("home")} className="cursor-pointer">Home</li>
            </ul>
            <ul class="list-disc list-inside my-2">
              <li onClick={() => navPage("story")} className="cursor-pointer">Story</li>
            </ul>
            <ul class="list-disc list-inside my-2">
              <li onClick={() => navPage("products")} className="cursor-pointer">Products</li>
            </ul>
            <ul class="list-disc list-inside my-2">
              <li onClick={() => navPage("contact")} className="cursor-pointer">Contact Us</li>
            </ul>
            <Button
              className="flex items-center gap-3 py-2 my-4 border-[#FED117] text-[#FED117] text-md"
              variant="outlined"
              onClick={() => window.open("https://gass.priapus.store/cta/")}
            >
              {/* <svg viewBox="0 0 24 24"
                className="w-10 h-full mx-3" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6.014 8.00613C6.12827 7.1024 7.30277 5.87414 8.23488 6.01043L8.23339 6.00894C9.14051 6.18132 9.85859 7.74261 10.2635 8.44465C10.5504 8.95402 10.3641 9.4701 10.0965 9.68787C9.7355 9.97883 9.17099 10.3803 9.28943 10.7834C9.5 11.5 12 14 13.2296 14.7107C13.695 14.9797 14.0325 14.2702 14.3207 13.9067C14.5301 13.6271 15.0466 13.46 15.5548 13.736C16.3138 14.178 17.0288 14.6917 17.69 15.27C18.0202 15.546 18.0977 15.9539 17.8689 16.385C17.4659 17.1443 16.3003 18.1456 15.4542 17.9421C13.9764 17.5868 8 15.27 6.08033 8.55801C5.97237 8.24048 5.99955 8.12044 6.014 8.00613Z" fill="#0F0F0F"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C10.7764 23 10.0994 22.8687 9 22.5L6.89443 23.5528C5.56462 24.2177 4 23.2507 4 21.7639V19.5C1.84655 17.492 1 15.1767 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM6 18.6303L5.36395 18.0372C3.69087 16.4772 3 14.7331 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C11.0143 21 10.552 20.911 9.63595 20.6038L8.84847 20.3397L6 21.7639V18.6303Z" fill="#0F0F0F"></path> </g></svg> */}
              Contact Us
            </Button>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
