import React from "react";
import ReactPlayer from "react-player/youtube";
const VideoPlayer = ({ url }) => {
  return (
    <div className="w-full h-full relative bg-warnaUtama aspect-video">
      <ReactPlayer
        url={url}
        width="100%"
        height={"100%"}
      />
    </div>
  );
};

export default VideoPlayer;
