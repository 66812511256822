import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

export default function RedirectToErojan() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/erojan");
  }, []);

  return null;
}
