import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Pagination } from "swiper/modules";
import "../timeline.css"

import React, { forwardRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { IoMaleOutline } from "react-icons/io5";
import { GiFat, GiTiredEye } from "react-icons/gi";
import { PiMaskSad } from "react-icons/pi";
import { FaUserShield, FaBed, FaRegTired, FaWhatsapp } from "react-icons/fa";
import { CgGym } from "react-icons/cg";
import { MdNoFood } from "react-icons/md";
import { Fa1, Fa2, Fa3, Fa4, Fa5 } from "react-icons/fa6";
import CreditCardIcon from '@rsuite/icons/legacy/CreditCard';
import { Navigation } from "swiper/modules";
import { Grid, Timeline } from "rsuite"

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import "../App.css";
// ..
import Countdown, {
  zeroPad,
  calcTimeDelta,
  formatTimeDelta,
} from "react-countdown";

import erojanCertificate1 from "../assets/EROJAN - drugs_BM.jpg";
import erojanCertificate2 from "../assets/EROJAN - Halal Certificate_BM.jpg";
import erojanCertificate3 from "../assets/EROJAN - No Heavy Metal_BM.jpg";
import erojanCertificate4 from "../assets/EROJAN - No Microbes_BM.jpg";
import erojanCertificate5 from "../assets/EROJAN - No Preservative_BM.jpg";
import erojanCertificate6 from "../assets/EROJAN_Halvec certificate_BM.jpg";

import erojanProduct from "../assets/ErojanProduct.JPG";
import erojanProduct2 from "../assets/ErojanProduct2.JPG";
import erojanStory from "../assets/Erojan Story 1.jpg";
import erojanText from "../assets/Salinan EROJAN logo_gold copy.png";
import risk from "../assets/risk.png";
// import testimonyVideo from "../assets/Professional Testimony_3.mp4";
// import testimonyVideo2 from "../assets/dr Rizki - Prostat - Wellous.mp4";
// import testimonyVideo3 from "../assets/KOL_Erojan 9.mp4";
import warningIcons from "../assets/WarningIcons.png";
import ErojanBottle from "../assets/ErojanBottle.png";
import ErojanBottlePS from "../assets/erojanBottlePS.JPG";
import ErojanBottlePS2 from "../assets/erojanBottlePS2.JPG";
import erojanAward from "../assets/Erojan_Awards_IG-Story-03.png";
import erojanAward2 from "../assets/Erojan_Awards_IG-Story-06.png";
import erojanCapsule from "../assets/Erojan- Three Capsule -b.png";

import penuaan from "../assets/penuaan.jpg"
import lemak from "../assets/lemak.jpg"
import depresi from "../assets/depresi.jpg"
import lelah from "../assets/lelah.jpg"
import libido from "../assets/libido.jpg"

import tongkatAli from "../assets/Tongkat-Ali_Alpha.png";
import maca from "../assets/maca.png";
import butea from "../assets/butea-superda.png";
import cucusta from "../assets/Cuscuta-Chinensis.png";
import tribulus from "../assets/Tribulus-Terrestris.png";
import hornyGoat from "../assets/Horny-Goat-Weed.png";
import VideoPlayer from "./VideoPlayer";

const Screen = forwardRef((props, ref) => {
  AOS.init();
  const awards = [erojanAward, erojanAward2];
  const certificates = [
    erojanCertificate1,
    erojanCertificate2,
    erojanCertificate3,
    erojanCertificate4,
    erojanCertificate5,
  ];

  const customCountdown = ({ hours, minutes, seconds }) => {
    return (
      <Typography variant="h1" className="py-5 text-white">
        {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </Typography>
    );
  };

  return (
    <div className="bg-[#393939]">
      {/* Hook */}
      {/* <div
        data-aos="fade-right"
        id="home"
        ref={ref[0].ref}
        className="relative w-full"
        style={{ height: "55rem" }}
      >
        <img
          className="h-full w-full block object-cover object-center"
          src={erojanProduct}
          alt="erojan product"
        />
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50">
          <figcaption className="absolute top-2 left-2/4 flex w-[calc(100%-22rem)] -translate-x-2/4 py-4 px-6 h-full">
            <div className="flex flex-col">
              <div className="flex flex-row justify-center items-center py-4">
                <div className="w-7/12 flex justify-center">
                  <Typography className="font-bold text-[#FED117] text-7xl">
                    Satu Kapsul Solusi Alami Masalah Pria
                  </Typography>
                </div>
                <div
                  data-aos="zoom-in-up"
                  data-aos-duration="1000"
                  className="flex justify-center w-6/12"
                >
                  <VideoPlayer url="https://youtu.be/g3SWBvXbjjA" />
                </div>
              </div>
              <div
                ref={ref[1].ref}
                id="story"
                className="flex flex-row justify-center py-8"
              >
                <div className="w-7/12 flex">
                  <img
                    className="lg:h-100 h-full w-10/12 rounded-xl object-cover object-center shadow-sm"
                    src={erojanStory}
                    alt="nature"
                  />
                </div>
                <div
                  data-aos="zoom-in-up"
                  data-aos-duration="1000"
                  className="flex justify-center flex-col w-6/12"
                >
                  <Typography className="text-lg lg:text-5xl font-bold text-[#FED117]">
                    BANYAK PRIA MENYADARI HIPOGONADISME KETIKA SUDAH TERLAMBAT
                  </Typography>
                  <Typography
                    className="mt-2 font-thin lg:text-2xl text-white"
                    variant="paragraph"
                  >
                    Tanda-tanda Anda mengalami Hipogonadisme (Penurunan Kadar
                    Testosterone) ?
                  </Typography>
                </div>
              </div>
            </div>
          </figcaption>
        </div>
      </div> */}
      <div
        className="relative bg-cover bg-center h-screen erojanBottle"
        id="home"
        ref={ref[0].ref}
      >
        <div class="absolute inset-0 bg-black opacity-50"></div>
        <div class="absolute inset-0 flex items-center justify-center">
          <div className="flex flex-col lg:flex-row justify-center items-center w-10/12 lg:h-96 h-3/4 mb-4">
            {/* <Typography
              variant="h2"
              className="font-bold text-gray-300 relative lg:right-32"
            > */}
            {/* EROJAN */}
            {/* <img className="lg:w-32 w-24" src={erojanText} /> */}
            {/* </Typography> */}
            <div className="flex flex-col justify-center px-4">
              <Typography className="font-bold text-[#FED117] text-6xl lg:text-8xl mb-7 MilliardBold">
                Satu Kapsul Solusi Alami Masalah Pria
              </Typography>
              <Typography className="font-thin text-white text-2xl mb-7 plusJakarta">
                Raih performa maksimal dimanapun dan kapanpun
              </Typography>
            </div>
            {/* <div
              data-aos="zoom-in-up"
              data-aos-duration="1000"
              className="flex justify-center h-96"
            > */}
            <VideoPlayer url="https://youtu.be/g3SWBvXbjjA" />
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* <hr className="bg-white w-11/12 m-auto"/> */}
      {/* <hr className="bg-white w-11/12 m-auto"/> */}
      {/* Story */}
      <div
        ref={ref[1].ref}
        id="story"
        className="flex flex-col lg:items-start items-center lg:flex-row justify-center py-4 bg-[#393939]"
      >
        <div>
          <img
            className="lg:h-100 h-full w-72 lg:mx-auto mx-2 rounded-xl object-cover object-center shadow-sm"
            src={erojanStory}
            alt="nature"
          />
        </div>
        <div className="lg:w-5/12 flex flex-col mx-4">
          <Typography className="lg:py-8 py-6 text-2xl lg:text-left text-center lg:text-4xl font-bold text-[#FED117]">
            CEPAT SADARI SEBELUM TERLAMBAT BAHAYA HIPOGONADISME!
          </Typography>
          <Typography
            className="mt-2 font-thin lg:text-left text-center lg:text-2xl text-white"
            variant="paragraph"
          >
            Tanda-tanda Anda mengalami Hipogonadisme (Penurunan Kadar
            Testosterone) ?
          </Typography>
        </div>
      </div>
      {/* Konsenkuensi */}
      <div className="flex flex-col justify-center items-center py-4 pb-10">
        <div className="flex items-center flex-col">
          <img className="object-center w-40" src={warningIcons} />
          <Typography variant="h2" className="font-bol text-[#FED117]">
            HATI - HATI
          </Typography>
        </div>
        {/* <Grid fluid> */}
        <div className="hidden lg:flex items-center justift-center flex-row w-8/12 right-4 relative">
          <Timeline align="alternate" className="custom-timeline my-8">
            <Timeline.Item dot={<Fa1 className="rs-icon" />} time={
              <div>
                <Typography variant="h2" className="mb-2 text-[#FED117]">
                  Penuaan Dini
                </Typography>
                <Typography className="text-white text-xl">
                  Performa tubuh <span className="text-[#FED117]">Tidak</span>{" "}
                  seperti dulu lagi. Penurunan kadar testosterone juga membuat
                  performa tubuh Anda menjadi tidak optimal, mudah lelah, gangguan
                  pada fungsi tubuh dan pikiran.
                </Typography>
              </div>}><img
                className="h-06 lg:w-96 w-24 mb-5"
                src={penuaan}
                alt="nature"
              /></Timeline.Item>
            <Timeline.Item dot={<Fa2 className="rs-icon" />} time={
              <div>
                <Typography variant="h2" className="mb-2 text-[#FED117]">
                  Gangguan Alat Vital dan Libido
                </Typography>
                <Typography className="text-white text-xl">
                  Penurunan kadar testosterone dapat menyebabkan gangguan dan
                  disfungsi alat vital, termasuk{" "}
                  <span className="text-[#FED117]">risiko prostat.</span>
                </Typography>
              </div>}><img
                className="h-06 lg:w-96 w-24 mb-5"
                src={libido}
                alt="nature"
              /></Timeline.Item>
            <Timeline.Item dot={<Fa3 className="rs-icon" />} time={
              <div>
                <Typography variant="h2" className="mb-2 text-[#FED117]">
                  Peningkatan Lemak Tubuh
                </Typography>
                <Typography className="text-white text-xl">
                  Penurunan kadar testosterone dapat menyebabkan penurunan masa
                  otot dan kegagalan distribusi lemak. Sehingga lemak rentan
                  berkumpul pada bagian perut yang menyebabkan{" "}
                  <span className="text-[#FED117]">perut buncit.</span>
                </Typography>
              </div>}><img
                className="h-06 lg:w-96 w-24 mb-5"
                src={lemak}
                alt="nature"
              /></Timeline.Item>
            <Timeline.Item dot={<Fa4 className="rs-icon" />} time={
              <div>
                <Typography variant="h2" className="mb-2 text-[#FED117]">
                  Stres, Depresi, dan Cemas
                </Typography>
                <Typography className="text-white text-xl">
                  Hormon testosterone berperan meningkatkan suasana hati.
                  Penurunan dapat {""}
                  <span className="text-[#FED117]">
                    berakibat stres, depresi, dan mood yang tidak baik.
                  </span>
                </Typography>
              </div>}><img
                className="h-06 lg:w-96 w-24 mb-5"
                src={depresi}
                alt="nature"
              /></Timeline.Item>
            <Timeline.Item dot={<Fa5 className="rs-icon" />} time={
              <div>
                <Typography variant="h2" className="mb-2 text-[#FED117]">
                  Mudah Lelah, Letih, Hilang Fokus
                </Typography>
                <Typography className="text-white text-xl">
                  Susah bangun pagi. Penurunan kadar testosterone dapat
                  mempengaruhi turunnya{" "}
                  <span className="text-[#FED117]">stamina </span>
                  sehingga menyebabkan Anda mudah lelah, letih, dan cape.
                </Typography>
              </div>}><img
                className="h-06 lg:w-96 w-24 mb-5"
                src={lelah}
                alt="nature"
              /></Timeline.Item>
          </Timeline>
        </div>
        <div className="flex lg:hidden items-center justift-center flex-row w-8/12">
          <Timeline className="custom-timeline my-8">
            <Timeline.Item dot={<Fa1 className="rs-icon" />}>
              <Typography variant="h5" className="mb-2 text-[#FED117]">
                Penuaan Dini
              </Typography>
              <Typography className="text-white">
                Performa tubuh <span className="text-[#FED117]">Tidak</span>{" "}
                seperti dulu lagi. Penurunan kadar testosterone juga membuat
                performa tubuh Anda menjadi tidak optimal, mudah lelah, gangguan
                pada fungsi tubuh dan pikiran.
              </Typography>
              <img
                className="h-full lg:w-96 w-48 my-5"
                src={penuaan}
                alt="nature"
              /></Timeline.Item>
            <Timeline.Item dot={<Fa2 className="rs-icon" />}>
              <Typography variant="h5" className="mb-2 text-[#FED117]">
                Gangguan Alat Vital dan Libido
              </Typography>
              <Typography className="text-white">
                Penurunan kadar testosterone dapat menyebabkan gangguan dan
                disfungsi alat vital, termasuk{" "}
                <span className="text-[#FED117]">risiko prostat.</span>
              </Typography>
              <img
                className="h-full lg:w-96 w-48 my-5"
                src={libido}
                alt="nature"
              /></Timeline.Item>
            <Timeline.Item dot={<Fa3 className="rs-icon" />} >
              <Typography variant="h5" className="mb-2 text-[#FED117]">
                Peningkatan Lemak Tubuh
              </Typography>
              <Typography className="text-white">
                Penurunan kadar testosterone dapat menyebabkan penurunan masa
                otot dan kegagalan distribusi lemak. Sehingga lemak rentan
                berkumpul pada bagian perut yang menyebabkan{" "}
                <span className="text-[#FED117]">perut buncit.</span>
              </Typography>
              <img
                className="h-full lg:w-96 w-48 my-5"
                src={lemak}
                alt="nature"
              /></Timeline.Item>
            <Timeline.Item dot={<Fa4 className="rs-icon" />}>
              <Typography variant="h5" className="mb-2 text-[#FED117]">
                Stres, Depresi, dan Cemas
              </Typography>
              <Typography className="text-white">
                Hormon testosterone berperan meningkatkan suasana hati.
                Penurunan dapat {""}
                <span className="text-[#FED117]">
                  berakibat stres, depresi, dan mood yang tidak baik.
                </span>
              </Typography>
              <img
                className="h-full lg:w-96 w-48 my-5"
                src={depresi}
                alt="nature"
              /></Timeline.Item>
            <Timeline.Item dot={<Fa5 className="rs-icon" />}>
              <Typography variant="h5" className="mb-2 text-[#FED117]">
                Mudah Lelah, Letih, Hilang Fokus
              </Typography>
              <Typography className="text-white">
                Susah bangun pagi. Penurunan kadar testosterone dapat
                mempengaruhi turunnya{" "}
                <span className="text-[#FED117]">stamina </span>
                sehingga menyebabkan Anda mudah lelah, letih, dan cape.
              </Typography>
              <img
                className="h-full lg:w-96 w-48 my-5"
                src={lelah}
                alt="nature"
              /></Timeline.Item>
          </Timeline>
        </div>
        {/* </Grid> */}

        {/* <div className="grid lg:grid-cols-3 lg:gap-5 justify-center">
          <Card className="lg:mt-6 w-80 bg-[#393939] border-none shadow-none">
            <CardBody className="flex flex-col items-center lg:p-6 p-3">
              <FaUserShield size="3.5rem" className="my-1" color="#FED117" />
              <Typography variant="h5" className="mb-2 text-[#FED117]">
                Penuaan Dini
              </Typography>
              <Typography className="text-white">
                Performa tubuh <span className="text-[#FED117]">Tidak</span>{" "}
                seperti dulu lagi. Penurunan kadar testosterone juga membuat
                performa tubuh Anda menjadi tidak optimal, mudah lelah, gangguan
                pada fungsi tubuh dan pikiran.
              </Typography>
            </CardBody>
          </Card>
          <Card className="lg:mt-6 w-80 bg-[#393939] border-none shadow-none">
            <CardBody className="flex flex-col items-center lg:p-6 p-3">
              <IoMaleOutline size="3.5rem" className="my-1" color="#FED117" />
              <Typography variant="h5" className="mb-2 text-[#FED117]">
                Gangguan Alat Vital dan Libido
              </Typography>
              <Typography className="text-white">
                Penurunan kadar testosterone dapat menyebabkan gangguan dan
                disfungsi alat vital, termasuk{" "}
                <span className="text-[#FED117]">risiko prostat.</span>
              </Typography>
            </CardBody>
          </Card>
          <Card className="lg:mt-6 w-80 bg-[#393939] border-none shadow-none">
            <CardBody className="flex flex-col items-center lg:p-6 p-3">
              <GiFat size="3.5rem" className="my-1" color="#FED117" />
              <Typography variant="h5" className="mb-2 text-[#FED117]">
                Peningkatan Lemak Tubuh
              </Typography>
              <Typography className="text-white">
                Penurunan kadar testosterone dapat menyebabkan penurunan masa
                otot dan kegagalan distribusi lemak. Sehingga lemak rentan
                berkumpul pada bagian perut yang menyebabkan{" "}
                <span className="text-[#FED117]">perut buncit.</span>
              </Typography>
            </CardBody>
          </Card>
        </div> */}
        {/* <div className="grid lg:grid-cols-2 lg:gap-5 justify-center">
          <Card className="lg:mt-6 w-80 bg-[#393939] border-none shadow-none">
            <CardBody className="flex flex-col items-center lg:p-6 p-3">
              <PiMaskSad size="3.5rem" className="my-1" color="#FED117" />
              <Typography variant="h5" className="mb-2 text-[#FED117]">
                Stres, Depresi, dan Cemas
              </Typography>
              <Typography className="text-white">
                Hormon testosterone berperan meningkatkan suasana hati.
                Penurunan dapat {""}
                <span className="text-[#FED117]">
                  berakibat stres, depresi, dan mood yang tidak baik.
                </span>
              </Typography>
            </CardBody>
          </Card>
          <Card className="lg:mt-6 w-80 bg-[#393939] border-none shadow-none">
            <CardBody className="flex flex-col items-center lg:p-6 p-3">
              <GiTiredEye size="3.5rem" className="my-1" color="#FED117" />
              <Typography variant="h5" className="mb-2 text-[#FED117]">
                Mudah Lelah, Letih, Hilang Fokus
              </Typography>
              <Typography className="text-white">
                Susah bangun pagi. Penurunan kadar testosterone dapat
                mempengaruhi turunnya{" "}
                <span className="text-[#FED117]">stamina </span>
                sehingga menyebabkan Anda mudah lelah, letih, dan cape.
              </Typography>
            </CardBody>
          </Card>
        </div> */}
        <Typography className="font-bold py-4 flex text-2xl text-[#FED117] lg:w-full w-3/4 justify-center lg:text-left text-center">
          Apakah Anda (atau Orang Tercinta Anda) adalah salah satunya?
        </Typography>
        <Card className="w-3/4 bg-[#393939] border-none shadow-none">
          <CardBody className="flex flex-col items-center">
            <div className="flex justify-center py-3 lg:h-96">
              {/* <video className="lg:h-96 w-full" controls>
                <source src={testimonyVideo2} type="video/mp4" />
                Your browser does not support the video tag.
              </video> */}
              <VideoPlayer url="https://youtu.be/OyWKV1vir9s" />
            </div>
          </CardBody>
        </Card>
        <div className="w-5/12 flex flex-col items-center mx-auto">
          <Typography className="font-bold py-4 flex text-4xl lg:text-4xl text-[#FED117] text-center">
            SADARI DAMPAK PANJANGNYA, KARENA
          </Typography>
          <Typography
            className="mt-2 font-thin text-gray-200 flex text-center"
            variant="paragraph"
          >
            Menurut Penelitian, hal ini karena mulai umur 30 tahun ke atas, Pria
            akan memiliki penurunan kadar testosteron sebesar 2-3% seiring
            bertambahnya usia.
          </Typography>
          <Typography className="font-bold py-4 flex text-4xl lg:text-3xl text-[#FED117] text-center">
            SADARI DAN CEGAH SEBELUM TERLAMBAT
          </Typography>
          {/* <Button
            className="flex items-center gap-3 py-1 my-4 lg:text-3xl bg-[#ffaf02] px-16"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            onClick={() => window.open("https://gass.priapus.store/cta/")}
          >
            <FaWhatsapp size="3.7rem" />
            Klik sekarang untuk FREE Konsultasi
          </Button> */}
          <a
            href="https://gass.priapus.store/cta"
            className="flex items-center gap-3 py-1 my-4 lg:text-3xl bg-[#ffaf02] px-16 text-white rounded-lg uppercase font-bold text-center font-sans"
            target="_blank"
            data-aos="fade-up"
            rel="noopener noreferrer"
            data-aos-anchor-placement="center-bottom"
          >
            <FaWhatsapp size="3.7rem" />
            <div>Klik sekarang untuk FREE Konsultasi</div>
          </a>
        </div>
      </div>
      {/* Erojan Logo */}

      <div className="flex flex-col justify-center items-center py-4 bg-[#393939]">
        <div className="w-10/12 flex flex-col">
          <div className="flex flex-col lg:flex-row justify-center items-center">
            <div className="py-5">
              <img
                className="h-72 lg:h-96 w-full rounded-xl object-cover object-center shadow-sm hover:scale-110"
                src={risk}
                alt="nature"
              />
            </div>
            <div className="flex flex-col">
              <Typography className="mt-10 font-thin text-[#FED117] text-2xl text-left justify-center flex">
                RISIKO AKAN MENINGKAT DRASTIS APABILA
              </Typography>
              <div className="mt-5 flex flex-row items-center">
                {/* <svg
                  viewBox="-2.4 -2.4 28.80 28.80"
                  className="w-12 h-full"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0">
                    <rect
                      x="-2.4"
                      y="-2.4"
                      width="28.80"
                      height="28.80"
                      rx="14.4"
                      fill="#b9b9b9"
                      strokewidth="0"
                    ></rect>
                  </g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6 7.5H7.5V11.25V12.75V16.5H6V7.5ZM9 12.75V18H4.5V16.5H1.5V7.5H4.5V6H9V11.25L15 11.25V6H19.5V7.5L22.5 7.5V16.5H19.5V18H15V12.75L9 12.75ZM16.5 12.75L16.5 16.5H18L18 7.5L16.5 7.5L16.5 11.25V12.75ZM4.5 9H3V15H4.5V9ZM19.5 15H21V9H19.5V15Z"
                      fill="#000000"
                    ></path>{" "}
                  </g>
                </svg> */}
                <span className="rounded-full p-1">
                  <CgGym size="2.5rem" className="text-[#FED117]" />
                </span>
                <Typography
                  className="mx-4 font-thin text-gray-200"
                  variant="paragraph"
                >
                  Tidak aktif berolahraga
                </Typography>
              </div>
              <div className="mt-5 flex flex-row items-center">
                {/* <svg
                  className="w-12 h-full"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-5.86 -5.86 70.36 70.36"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0">
                    <rect
                      x="-5.86"
                      y="-5.86"
                      width="70.36"
                      height="70.36"
                      rx="35.18"
                      fill="#b9b9b9"
                      strokewidth="0"
                    ></rect>
                  </g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g>
                      {" "}
                      <path
                        fill="#010002"
                        d="M57.512,28.193h-2.908c-0.622,0-1.126,0.505-1.126,1.125v2.438h-1.923v-9.943 c0-0.622-0.504-1.126-1.126-1.126H20.031c-0.622,0-1.126,0.504-1.126,1.126v9.944h-4.996c2.419-0.63,4.212-2.825,4.212-5.441 c0-3.104-2.524-5.629-5.629-5.629s-5.629,2.525-5.629,5.629c0,2.616,1.793,4.812,4.212,5.441H5.16v-17.45 c0-0.622-0.504-1.125-1.126-1.125H1.126C0.504,13.182,0,13.685,0,14.307V44.33c0,0.621,0.504,1.126,1.126,1.126h2.909 c0.622,0,1.126-0.505,1.126-1.126v-4.504h48.317v4.504c0,0.621,0.504,1.126,1.126,1.126h2.908c0.621,0,1.126-0.505,1.126-1.126 V29.318C58.638,28.698,58.133,28.193,57.512,28.193z"
                      ></path>{" "}
                    </g>{" "}
                  </g>
                </svg> */}
                <span className="rounded-full p-1">
                  <FaBed size="2.5rem" className="text-[#FED117]" />
                </span>
                <Typography
                  className="mx-4 font-thin text-gray-200"
                  variant="paragraph"
                >
                  Tidur tidak teratur
                </Typography>
              </div>
              <div className="mt-5 flex flex-row items-center">
                {/* <svg
                  fill="#000000"
                  className="w-12 h-full"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-3.2 -3.2 38.40 38.40"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0">
                    <rect
                      x="-3.2"
                      y="-3.2"
                      width="38.40"
                      height="38.40"
                      rx="19.2"
                      fill="#b9b9b9"
                      strokewidth="0"
                    ></rect>
                  </g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g>
                      {" "}
                      <path d="M3,22h1.5h23.1H29c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1c-0.2-5.7-4.1-10.4-9.3-11.7C18.9,7.9,19,7.5,19,7c0-1.7-1.3-3-3-3 s-3,1.3-3,3c0,0.5,0.1,0.9,0.3,1.3C8.1,9.6,4.2,14.3,4,20H3c-0.6,0-1,0.4-1,1S2.4,22,3,22z"></path>{" "}
                      <path d="M5.5,24l0.8,1.5C6.4,25.8,6.8,26,7.2,26h17.7c0.4,0,0.7-0.2,0.9-0.5l0.8-1.5H5.5z"></path>{" "}
                    </g>{" "}
                  </g>
                </svg> */}
                <span className="rounded-full p-1">
                  <MdNoFood size="2.5rem" className="text-[#FED117]" />
                </span>
                <Typography
                  className="mx-4 font-thin text-gray-200"
                  variant="paragraph"
                >
                  Makan Makanan tidak bergizi
                </Typography>
              </div>
              <div className="mt-5 flex flex-row items-center">
                {/* <svg
                  viewBox="-6.4 -6.4 76.80 76.80"
                  className="w-12 h-full"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0">
                    <rect
                      x="-6.4"
                      y="-6.4"
                      width="76.80"
                      height="76.80"
                      rx="38.4"
                      fill="#b9b9b9"
                      strokewidth="0"
                    ></rect>
                  </g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2zm0 57.5C16.836 59.5 4.5 47.164 4.5 32S16.836 4.5 32 4.5S59.5 16.836 59.5 32S47.164 59.5 32 59.5z"
                      fill="#000000"
                    ></path>
                    <path
                      d="M50.502 16.529c.703-.135.193-2.27-.387-2.156c-4.168.748-8.457-.4-11.691-3.133c-.445-.387-1.955 1.205-1.414 1.674a16.414 16.414 0 0 0 13.492 3.615"
                      fill="#000000"
                    ></path>
                    <path
                      d="M13.5 16.377a16.424 16.424 0 0 0 13.492-3.615c.541-.469-.969-2.063-1.412-1.674a14.236 14.236 0 0 1-11.693 3.133c-.578-.114-1.09 2.021-.387 2.156"
                      fill="#000000"
                    ></path>
                    <path
                      d="M32 32c-8.838-.064-15.945 5.437-16 12.284c-.033 3.789 1.33 9.467 5.57 9.498c3.076.023 6.484-3.809 10.43-3.783c3.9.029 7.121 3.982 10.172 4c3.75.027 5.799-5.654 5.828-9.482c.059-6.844-7.166-12.451-16-12.517m0 15.999c-4 0-8 3-8 3s2-5 8-5s8 5 8 5s-4-3-8-3m9.402-10H32h-9.402c-.699 0-.744-.658-.348-.961c2.467-1.883 5.652-3.039 9.75-3.039c4.1 0 7.283 1.156 9.75 3.039c.396.303.352.961-.348.961"
                      fill="#000000"
                    ></path>
                    <path
                      d="M52.25 29.379c-1.752-2.1-4.98-4.162-9.002-4.829c2.709-1.5 5.563-1.958 8.256-2.361a.584.584 0 0 0 .166-1.094c-5.697-3.397-14.963-.679-16.609 6.525c-.104.446.314.728.801.678c6.168-.634 11.074.304 15.846 2.036c.442.161.937-.481.542-.955"
                      fill="#000000"
                    ></path>
                    <path
                      d="M28.141 28.298c.486.05.902-.231.801-.678c-1.646-7.204-10.912-9.923-16.611-6.525a.584.584 0 0 0 .168 1.094c2.693.403 5.547.861 8.256 2.361c-4.021.667-7.25 2.729-9.002 4.829c-.396.474.1 1.116.541.955c4.772-1.732 9.679-2.67 15.847-2.036"
                      fill="#000000"
                    ></path>
                  </g>
                </svg> */}
                <span className="rounded-full p-1">
                  <FaRegTired size="2.5rem" className="text-[#FED117]" />
                </span>
                <Typography
                  className="mx-4 font-thin text-gray-200"
                  variant="paragraph"
                >
                  Kurang Istirahat
                </Typography>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center w-5/12 mx-auto">
            <Typography className="font-bold py-4 my-4 flex text-4xl lg:text-4xl text-[#FED117] text-center">
              SADARI DAN CEGAH SEBELUM TERLAMBAT
            </Typography>
          </div>
          <div className="flex justify-center">
            {/* <Button
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              className="flex items-center gap-3 py-1 my-4 lg:text-3xl bg-[#ffaf02] px-16"
              onClick={() => window.open("https://gass.priapus.store/cta/")}
            > */}
            {/* <svg viewBox="0 0 24 24"
                className="w-10 h-full mx-3" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6.014 8.00613C6.12827 7.1024 7.30277 5.87414 8.23488 6.01043L8.23339 6.00894C9.14051 6.18132 9.85859 7.74261 10.2635 8.44465C10.5504 8.95402 10.3641 9.4701 10.0965 9.68787C9.7355 9.97883 9.17099 10.3803 9.28943 10.7834C9.5 11.5 12 14 13.2296 14.7107C13.695 14.9797 14.0325 14.2702 14.3207 13.9067C14.5301 13.6271 15.0466 13.46 15.5548 13.736C16.3138 14.178 17.0288 14.6917 17.69 15.27C18.0202 15.546 18.0977 15.9539 17.8689 16.385C17.4659 17.1443 16.3003 18.1456 15.4542 17.9421C13.9764 17.5868 8 15.27 6.08033 8.55801C5.97237 8.24048 5.99955 8.12044 6.014 8.00613Z" fill="#0F0F0F"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C10.7764 23 10.0994 22.8687 9 22.5L6.89443 23.5528C5.56462 24.2177 4 23.2507 4 21.7639V19.5C1.84655 17.492 1 15.1767 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM6 18.6303L5.36395 18.0372C3.69087 16.4772 3 14.7331 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C11.0143 21 10.552 20.911 9.63595 20.6038L8.84847 20.3397L6 21.7639V18.6303Z" fill="#0F0F0F"></path> </g></svg> */}
            {/* <FaWhatsapp size="3.7rem" />
              Klik sekarang untuk FREE Konsultasi
            </Button> */}
            <a
              href="https://gass.priapus.store/cta"
              className="flex items-center gap-3 py-1 my-4 lg:text-3xl bg-[#ffaf02] px-16 text-white rounded-lg uppercase font-bold text-center font-sans"
              target="_blank"
              rel="noopener noreferrer"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
            >
              <FaWhatsapp size="3.7rem" />
              <div>Klik sekarang untuk FREE Konsultasi</div>
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-center py-4 items-center">
        {/* <div className="grid lg:grid-cols-2 items-center py-4 px-4"> */}
        <div
          className="flex flex-col items-center"
          ref={ref[2].ref}
          id="products"
        >
          <Typography className="font-bold py-4 flex text-3xl lg:text-2xl text-center text-[#FED117]">
            CEGAH SEBELUM TERLAMBAT DENGAN
          </Typography>
          <div className="flex flex-row justify-center">
            <div className="w-8/12 flex flex-col">
              <img className="w-96 m-auto" src={erojanText} />
              <hr className="bg-white w-3/12 m-auto" />
            </div>
          </div>
          <Typography
            variant="h4"
            className="font-bold py-4 flex text-center text-white"
          >
            Testosterone Booster Alami #1 Malaysia.
          </Typography>
        </div>
        <div className="h-56 lg:h-96 flex flex-row">
          {/* {awards.map((e) => {
            return (
              <img
                className="h-full w-auto object-scale-down object-center shadow-sm px-4"
                src={e}
                alt="nature"
              />
            );
          })} */}
          <img
            className="h-full w-auto object-scale-down object-center shadow-sm px-2"
            src={ErojanBottlePS}
            alt="nature"
          />
          <img
            className="h-full w-auto object-scale-down object-center shadow-sm "
            src={ErojanBottlePS2}
            alt="nature"
          />
        </div>
      </div>
      <div className="flex flex-col justify-center bg-[#393939] py-4">
        <div className="flex flex-col items-center">
          {/* <Typography className="font-bold py-4 flex text-4xl text-[#FED117]">
            Mengapa EROJAN?
          </Typography> */}
          <Typography
            variant="h6"
            className="font-bold py-4 flex text-gray-300 lg:text-center text-center"
          >
            Erojan merupakan Testosterone Booster yang dirancang secara
            profesional <br />
            untuk memberikan efek kontrol terstosteron yang optimal.
            <br />
            Terbuat dari 6 bahan alami
          </Typography>
        </div>
        <div className="flex lg:flex-row flex-col items-center justify-center">
          <img
            className="h-06 lg:w-96 w-72 object-scale-down object-center shadow-sm top-2 relative px-2"
            src={erojanCapsule}
            alt="nature"
          />
          <div className="flex flex-col">
            <div className="grid grid-cols-3">
              <div className="border-b px-4 py-4">
                <img
                  className="h-20 w-20 rounded-full object-contain object-center mx-auto"
                  src={tongkatAli}
                  alt="nature image"
                />
                <Typography
                  variant="paragraph"
                  className="font-bold py-4 flex justify-center text-gray-300"
                >
                  Tongkat Ali
                </Typography>
              </div>
              <div className="border-b border-l border-r px-4 py-4">
                <img
                  className="h-20 w-20 rounded-full object-contain object-center mx-auto"
                  src={maca}
                  alt="nature image"
                />
                <Typography
                  variant="h6"
                  className="font-bold py-4 flex text-gray-300 justify-center"
                >
                  Maca
                </Typography>
              </div>
              <div className="border-b px-4 py-4">
                <img
                  className="h-20 w-20 rounded-full object-contain object-center mx-auto"
                  src={butea}
                  alt="nature image"
                />
                <Typography
                  variant="h6"
                  className="font-bold py-4 flex justify-center text-gray-300"
                >
                  Butea Superba
                </Typography>
              </div>
            </div>
            <div className="grid grid-cols-3">
              <div className=" px-4 py-4">
                <img
                  className="h-20 w-20 rounded-full object-contain object-center mx-auto"
                  src={tribulus}
                  alt="nature image"
                />
                <Typography
                  variant="h6"
                  className="font-bold py-4 flex justify-center text-gray-300"
                >
                  Tribulus Terrestris
                </Typography>
              </div>
              <div className="border-l border-r px-4 py-4">
                <img
                  className="h-20 w-20 rounded-full object-contain object-center mx-auto"
                  src={cucusta}
                  alt="nature image"
                />
                <Typography
                  variant="h6"
                  className="font-bold py-4 flex justify-center text-gray-300"
                >
                  Chuscuta Chinensis
                </Typography>
              </div>
              <div className="px-4 py-4">
                <img
                  className="h-20 w-20 rounded-full object-contain object-center mx-auto"
                  src={hornyGoat}
                  alt="nature image"
                />
                <Typography
                  variant="h6"
                  className="font-bold py-4 flex justify-center text-gray-300"
                >
                  Horny Goat Weed
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-col justify-center py-4 items-center">
          {/* <div className="grid lg:grid-cols-2 items-center py-4 px-4"> */}
          <div className="flex flex-col items-center">
            <Typography className="font-bold py-4 flex lg:text-left lg:text-4xl text-center text-[#FED117]">
              Telah dipercaya oleh hampir 100.000 pria di seluruh Asia.
            </Typography>
            {/* <div className="flex flex-row justify-center">
              <div className="w-8/12 flex flex-col">
                <img className="w-96 m-auto" src={erojanText} />
                <hr className="bg-white w-3/12 m-auto" />
              </div>
            </div>
            <Typography
              variant="h4"
              className="font-bold py-4 flexlg:text-left text-center text-white"
            >
              Testosterone Booster Alami #1 Malaysia.
            </Typography> */}
          </div>
          <div className="h-full lg:h-96 flex lg:flex-row flex-col">
            {/* {awards.map((e) => {
            return (
              <img
                className="h-full w-auto object-scale-down object-center shadow-sm px-4"
                src={e}
                alt="nature"
              />
            );
          })} */}
            <img
              className="h-full w-72 object-center shadow-sm relative px-2 py-2"
              src={erojanAward}
              alt="nature"
            />
            <img
              className="h-full w-72 object-center shadow-sm relative"
              src={erojanAward2}
              alt="nature"
            />
          </div>
        </div>
        <div className="flex flex-col items-center py-4">
          <Typography className="font-bold py-4 flex text-4xl text-[#FED117] lg:text-left text-center">
            TERBUKTI AMAN DAN TERPERCAYA.
          </Typography>
          {/* <Typography
            variant="h6"
            className="font-bold py-4 flex text-gray-300"
          >
            Erojan merupakan Testosterone Booster yang dirancang secara
            profesional <br />
            untuk memberikan efek kontrol terstosteron yang optimal.
            <br />
            Terbuat dari 6 bahan alami
          </Typography> */}
        </div>
        <div>
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            // pagination={true}
            // navigation={true}
            // modules={[Navigation]}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={true}
            modules={[EffectCoverflow]}
            className="swiper mySwiper swiper-coverflow swiper-3d swiper-initialized swiper-horizontal swiper-watch-progress"
          >
            {certificates.map((e) => {
              return (
                <SwiperSlide className="bg-cover bg-center sm:swiper-slide-mobile lg:swiper-slide">
                  <img src={e} className="w-full" />
                </SwiperSlide>
              );
            })}
            {/* <SwiperSlide>
              <img
                src="https://swiperjs.com/demos/images/nature-2.jpg"
                className="w-full"
              />
            </SwiperSlide> */}
          </Swiper>
        </div>
        <div
          ref={ref[3].ref}
          id="contact"
          className="flex flex-col items-center"
        >
          <Typography className="font-bold py-5 flex text-4xl text-[#FED117] lg:text-left text-center mx-2">
            JADILAH SUAMI IDAMAN MULAI HARI INI
          </Typography>
        </div>
      </div>
      <div className="flex flex-row justify-center py-4 pb-10">
        <div className="flex flex-col items-center">
          <Typography className=" text-3xl font-bold text-[#FED117] lg:text-left text-center mx-2">
            KESEMPATAN TERAKHIR UNTUK HARGA KHUSUS
          </Typography>
          <Countdown
            date={Date.now() + 860000}
            renderer={customCountdown}
          ></Countdown>
          <div className="flex justify-center my-5">
            {/* <Button
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              className="flex items-center gap-3 py-2 my-4 bg-[#ffaf02] lg:text-3xl mx-4 px-7"
              onClick={() => window.open("https://gass.priapus.store/cta/")}
            > */}
            {/* <svg viewBox="0 0 24 24"
                className="w-10 h-full mx-3" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6.014 8.00613C6.12827 7.1024 7.30277 5.87414 8.23488 6.01043L8.23339 6.00894C9.14051 6.18132 9.85859 7.74261 10.2635 8.44465C10.5504 8.95402 10.3641 9.4701 10.0965 9.68787C9.7355 9.97883 9.17099 10.3803 9.28943 10.7834C9.5 11.5 12 14 13.2296 14.7107C13.695 14.9797 14.0325 14.2702 14.3207 13.9067C14.5301 13.6271 15.0466 13.46 15.5548 13.736C16.3138 14.178 17.0288 14.6917 17.69 15.27C18.0202 15.546 18.0977 15.9539 17.8689 16.385C17.4659 17.1443 16.3003 18.1456 15.4542 17.9421C13.9764 17.5868 8 15.27 6.08033 8.55801C5.97237 8.24048 5.99955 8.12044 6.014 8.00613Z" fill="#0F0F0F"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C10.7764 23 10.0994 22.8687 9 22.5L6.89443 23.5528C5.56462 24.2177 4 23.2507 4 21.7639V19.5C1.84655 17.492 1 15.1767 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM6 18.6303L5.36395 18.0372C3.69087 16.4772 3 14.7331 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C11.0143 21 10.552 20.911 9.63595 20.6038L8.84847 20.3397L6 21.7639V18.6303Z" fill="#0F0F0F"></path> </g></svg> */}
            {/* <FaWhatsapp size="3.7rem" />
              Chat sekarang untuk Mendapatkan HARGA PROMO
            </Button> */}
            <a
              target="_blank"
              href="https://gass.priapus.store/cta"
              className="flex items-center gap-3 py-1 my-4 lg:text-3xl bg-[#ffaf02] px-16 text-white rounded-lg uppercase font-bold text-center font-sans"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
            >
              <FaWhatsapp size="3.7rem" />
              <div>Chat sekarang untuk Mendapatkan HARGA PROMO</div>
            </a>
          </div>
          {/* <video className="lg:h-96 w-7/12" controls>
            <source src={testimonyVideo3} type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
          <VideoPlayer url="https://youtu.be/uRvSlD7WP7M" />
        </div>
      </div>
    </div>
  );
});

export default Screen;
