import React from "react";
import {
  Button,
  IconButton,
  MobileNav,
  Navbar,
  Typography,
} from "@material-tailwind/react";
import Logo from "../logo.svg";
import LogoIcons from "../assets/Salinan logo wellous-01.png";

const Header = ({ refs }) => {
  // console.log(document.getElementById("home"));
  const navPageWithRef = (page) => {
    const result = refs.find(({ pageRef }) => pageRef === page);
    console.log(result);
    result.ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const navPage = (page) => {
    const id = page;
    const yOffset = -75;
    const element = document.getElementById(id);
    const navElement = document.getElementById("navbar")
    // console.log(navElement.offsetHeight);
    const y = element.getBoundingClientRect().top + window.scrollY - navElement.offsetHeight;
    // const y = element.getBoundingClientRect().top + window.scrollY + window.pageYOffset;
    setOpenNav(false)
    // console.log(y,element.getBoundingClientRect().top,window.scrollY,'asdasd')
    if (id === "home") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    else {
      window.scrollTo({ top: y, behavior: "smooth" });
      // element.scrollIntoView({inline:"nearest",behavior:"smooth"})
    }
  };

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <Typography
        as="li"
        variant="small"
        color="white"
        className="
        p-1 
        font-normal 
        underlinehover"
      >
        <a
          onClick={() => navPage("home")}
          className="cursor-pointer flex items-center text-[#ffffff]"
        >
          Home
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="white"
        className="p-1 font-normal underlinehover"
      >
        <a
          onClick={() => navPage("story")}
          className="flex items-center text-[#ffffff] cursor-pointer"
        >
          Story
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="white"
        className="p-1 font-normal underlinehover"
      >
        <a
          onClick={() => navPage("products")}
          className="cursor-pointer flex items-center text-[#ffffff]"
        >
          Products
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="white"
        className="p-1 font-normal underlinehover"
      >
        <a
          onClick={() => navPage("contact")}
          className="cursor-pointer flex items-center text-[#ffffff]"
        >
          Contact Us
        </a>
      </Typography>
    </ul>
  );
  const [openNav, setOpenNav] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);
  return (
    <>
      <Navbar id="navbar" className="sticky inset-0 z-10 block h-max w-full max-w-full rounded-none border border-[#393939] bg-[#000000] py-2 px-4 lg:px-8 lg:py-4 ">
        <div className="flex items-center justify-around text-blue-gray-900">
          <Typography className="mr-4 cursor-pointer py-1.5 font-medium text-white">
            {/* <img src={LogoIcons} className="w-full h-5" alt="logo" /> */}
            Priapus Store
          </Typography>
          <div className="flex items-center gap-4">
            <div className="mr-4 hidden lg:block">{navList}</div>
          </div>
          {/* <Button className='mr-4 hidden lg:block' color="red">filled</Button> */}
          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent text-black focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                stroke="white"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                stroke="white"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </IconButton>
        </div>
        <MobileNav open={openNav}>{navList}</MobileNav>
      </Navbar>
      {/* <div className="h-3"></div> */}
    </>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
};

export default Header;
