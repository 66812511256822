// import './App.css';
import React, { useRef, useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useHistory,
} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Screen from "./components/Screen";
import StickyWA from "./components/StickyWA";
import RedirectToErojan from "./components/redirectToErojan";

const App = () => {
  useEffect(() => {
    // Load Facebook Pixel script
    const loadFacebookPixelScript = () => {
      // Load Facebook Pixel script
      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
    };

    // Load GASS script
    const loadGASScript = () => {
      const gassScript = document.createElement("script");
      gassScript.src = "https://gass.co.id/gassv3.min.js?v=3";
      gassScript.async = true;
      document.head.appendChild(gassScript);
      return gassScript;
    };

    // Run GASS script when loaded
    const runGASScript = (script) => {
      script.onload = () => {
        window.gass.run(
          { campaign_id: 1, subdomain: "gass.priapus.store", interval: 2 },
          function (data) {}
        );
      };
    };

    // Load Facebook Pixel script
    loadFacebookPixelScript();

    // Load GASS script
    const gassScript = loadGASScript();

    // Run GASS script when loaded
    runGASScript(gassScript);
    // Clean up
    return () => {
      document.head.removeChild(gassScript);
    };
  }, []);

  // useEffect(() => {
  //   // Load Facebook Pixel script
  //   (function (f, b, e, v, n, t, s) {
  //     if (f.fbq) return;
  //     n = f.fbq = function () {
  //       n.callMethod
  //         ? n.callMethod.apply(n, arguments)
  //         : n.queue.push(arguments);
  //     };
  //     if (!f._fbq) f._fbq = n;
  //     n.push = n;
  //     n.loaded = !0;
  //     n.version = "2.0";
  //     n.queue = [];
  //     t = b.createElement(e);
  //     t.async = !0;
  //     t.src = v;
  //     s = b.getElementsByTagName(e)[0];
  //     s.parentNode.insertBefore(t, s);
  //   })(
  //     window,
  //     document,
  //     "script",
  //     "https://connect.facebook.net/en_US/fbevents.js"
  //   );

  //   // Load GASS script
  //   const gassScript = document.createElement("script");
  //   // gassScript.type = "text/javascript";
  //   gassScript.src = "https://gass.co.id/gassv3.min.js?v=3";
  //   // gassScript.async = true;

  //   // Run GASS script when loaded
  //   gassScript.onload = () => {
  //     window.gass.run(
  //       { campaign_id: 1, subdomain: "gass.priapus.store", interval: 2 },
  //       function (data) {
  //         console.log(data);
  //       }
  //     );
  //     console.log(gassScript,window.gass,'asdasd')
  //   };

  //   // Append GASS script to document body
  //   document.head.appendChild(gassScript);
  //   // document.body.appendChild(gassScript);

  //   // Clean up
  //   return () => {
  //     document.head.removeChild(gassScript);
  //     // document.body.removeChild(gassScript);
  //   };
  // }, []);

  const homeRef = useRef(null);
  const storyRef = useRef(null);
  const productsRef = useRef(null);
  const contactRef = useRef(null);
  const refs = [
    { pageRef: "home", ref: homeRef },
    { pageRef: "story", ref: storyRef },
    { pageRef: "products", ref: productsRef },
    { pageRef: "contact", ref: contactRef },
  ];

  return (
    // <div className="bg-[#393939]">
    <>
      <Router>
        <Header refs={refs} />
        <Routes>
          <Route>
            <Route path="/" element={<RedirectToErojan />} />
            <Route exact path="/erojan" element={<Screen ref={refs} />} />
            {/* <Screen ref={refs} /> */}
          </Route>
        </Routes>
        <Footer refs={refs} />
        <StickyWA />
      </Router>
    </>
    // </div>
  );
};

export default App;
